import React from "react";
import {useGlobalFilter, useTable} from "react-table";
import {Skeleton, Table, TableCell, TableHeading} from "@nike/eds";
import {GlobalFilter} from "../util/GlobalFilter";

interface FilterableTableProps {
  data: object;
  columns: object;
  loading: boolean;
}

export function FilterableTable({
  data,
  columns,
  loading,
}: FilterableTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      loading,
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  return loading ? (
    <div></div>
  ) : (
    <div className="eds-grid">
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeading {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableHeading>
              ))}
              <th></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!loading &&
            rows.map((row) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();

              return (
                <React.Fragment key={key}>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {// Render the cell contents
                          cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          {loading &&
            headerGroups.map((headerGroup) => (
              <>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell>
                      <Skeleton height={35} width={"100%"} />
                    </TableCell>
                  ))}
                  <th></th>
                </tr>
              </>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

import React, {useContext, useState} from "react";
import {IconButton, Spinner, Tooltip} from "@nike/eds";
import SnackbarContext from "../../providers/snackbar-context";
import * as vkbeautify from "vkbeautify";
import {v4 as uuidv4} from 'uuid';
import {DownloadModal} from "../detail/DownloadModal";

function PayloadView(props) {

    let payload = undefined;
    let preSignedUrl = undefined;
    const payloadAvailable = props.payloadPath !== undefined && props.payloadPath !== null;
    const previewAvailable = props.payloadSize !== undefined && props.payloadSize < 70000000;
    const id = uuidv4();
    const structuredLogsService = props.structuredLogsService;
    const [downloading, setDownloading] = useState(false);
    const snackbarCtx = useContext(SnackbarContext);
    const [downloadModalJsonContent, setDownloadModalJsonContent] = useState<
        boolean
    >(false);
    const [downloadModalContent, setDownloadModalContent] = useState<string>("");
    const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);

    const downloadPayload = async (e, urlOnly) => {
        if (payload === undefined) {
            setDownloading(true);
            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(5000);
            const region = props.payloadRegion;
            const bucket = props.payloadBucket;
            const key = props.payloadPath;
            const version = props.payloadVersion;
            await structuredLogsService
                .getPayload(region, bucket, key, version, urlOnly)
                .then((result) => {
                    if (urlOnly) {
                        preSignedUrl = result.data.getPayloadUrlOnly.preSignedUrl;
                    } else {
                        payload = result.data.getPayload.content;
                        preSignedUrl = result.data.getPayload.preSignedUrl;
                    }
                })
                .catch((error) => {
                    snackbarCtx.displayMsg(error.message, "error");
                })
                .finally(() => {
                    setDownloading(false);
                });
        }
    };

    const handleDownloadClicked = async (e) => {
        await downloadPayload(e, true);
        if (preSignedUrl !== undefined) {
            const fileName = props.payloadPath;
            fetch(preSignedUrl).then(function (t) {
                return t.blob().then((b) => {
                    const a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", fileName);
                    a.click();
                });
            });
        }
    };

    const handleViewClicked = async (e) => {
        await downloadPayload(e, false);
        try {
            let formattedJson = vkbeautify.json(payload, 2);
            setDownloadModalJsonContent(true);
            setDownloadModalContent(formattedJson);
        } catch {
            setDownloadModalJsonContent(false);
            setDownloadModalContent(payload !== undefined ? payload : "Not available");
        }
        setDownloadModalOpen(true);
    };

    return (
         <div >
             { payloadAvailable ? (<>
                 <div>
                     {downloading ? (
                         <Spinner size="medium"/>
                     ) : (
                         <>
                             <IconButton
                                 variant="ghost"
                                 id={id + 'download'}
                                 size="medium"
                                 icon="Download"
                                 label=""
                                 onClick={(e) => {
                                     handleDownloadClicked(e);
                                 }}
                                 disabled={downloading}
                             >
                             </IconButton>
                             {previewAvailable ? (
                                 <IconButton
                                     variant="ghost"
                                     id={id + 'view'}
                                     size="medium"
                                     icon="Show"
                                     label=""
                                     onClick={(e) => {
                                         handleViewClicked(e);
                                     }}
                                     disabled={downloading}
                                 >
                                 </IconButton>
                             ) : (
                                 <Tooltip bodySlot={"No preview available, use download instead."}>
                                     <IconButton
                                         variant="ghost"
                                         id={id + 'view'}
                                         size="medium"
                                         icon="Show"
                                         label=""
                                         onClick={(e) => {
                                             handleViewClicked(e);
                                         }}
                                         disabled
                                     >
                                     </IconButton>
                                 </Tooltip>
                             )}
                         </>
                     )}
                 </div>
                 <DownloadModal
                     downloadModalOpen={downloadModalOpen}
                     setDownloadModalOpen={setDownloadModalOpen}
                     content={downloadModalContent}
                     jsonContent={downloadModalJsonContent}/></>) : ( <></>)
             }
        </div>
    );
}

export {PayloadView};

import {useEffect, useState} from 'react';

type stateType<T> = {value: T, restored: boolean};

const useSessionStorage = <T>(
  key: string,
  initialValue?: T,
  raw?: boolean
): [stateType<T>, (value: T) => void] => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = useState<stateType<T>>(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
        return { value: initialValue, restored: true};
      } else {
        return {
            value: raw ? sessionStorageValue : JSON.parse(sessionStorageValue || 'null'),
            restored: true
        };
      }
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return {
        value: initialValue,
        restored: true
      }
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    try {
      const serializedState = raw ? String(state.value) : JSON.stringify(state.value);
      sessionStorage.setItem(key, serializedState);
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. Also JSON.stringify can throw.
    }
  });

  return [state, (value: T) => {
    setState({
        value: value,
        restored: false
    });
  }];
};

export default useSessionStorage;
import {Box, Tab, TabGroup} from "@nike/eds";
import React from "react";
import {RequestDetailOverviewTab} from "./RequestDetailOverviewTab"

function RequestDetailOverview(props) {

    const [activeId, setActiveId] = React.useState("documentEventsTab");

    return (
        <Box className="nesg-grid nesg-grid-md-cols-2 nesg-grid-items-center">
            <div style={{backgroundColor: "white"}}>
                <TabGroup
                    className="eds-spacing--m-12 eds-spacing--pt-12"
                    name="tab-button-group"
                    activeId={activeId}
                    onChange={e => setActiveId(e.target.id)}
                >
                    <Tab id="documentEventsTab">Document Events</Tab>
                    <Tab id="loggingTab">Logging</Tab>
                </TabGroup>
                <div hidden={activeId !== 'documentEventsTab'}>
                    <RequestDetailOverviewTab activeId={activeId}
                                              structuredLogsService={props.structuredLogsService}
                                              traceId={props.traceId}
                                              logType="EVENT" />
                </div>
                <div hidden={activeId !== 'loggingTab'}>
                    <RequestDetailOverviewTab activeId={activeId}
                        structuredLogsService={props.structuredLogsService}
                        traceId={props.traceId}
                        logType="LOGMESSAGE" />
                </div>
            </div>
        </Box>
    );
}

export {RequestDetailOverview};
import React from "react";
import {Skeleton, Table, TableCell, TableHeading} from "@nike/eds";
import {FlowSearchResultItem} from "./FlowSearchResultItem";

function FlowSearchResults(props) {

    const headers = [
        {Header: ""},
        {Header: "Event timestamp"},
        {Header: "Business Reference"},
        {Header: "Type"},
        {Header: "Detail"},
        {Header: "Shipping Point"},
        {Header: "Inventory Location"},
        {Header: "Status"},
        {Header: "Error"}
    ];

    return (
         <div className="eds-grid eds-grid--m-cols-6 eds-elevation--4">
            <Table className="eds-grid--m-col-6 eds-grid--item-align-start">
                <thead>
                <tr>
                    {headers.map(column => (<TableHeading key={column.Header}>{column.Header}</TableHeading>))}
                </tr>
                </thead>
                <tbody>
                { props.loading &&
                    <>
                        <tr>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                            <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        </tr>
                    </>
                }
                {!props.loading && props.searchResults.map(searchResult => (
                    <FlowSearchResultItem searchResult={searchResult} structuredLogsService={props.structuredLogsService}/>
                ))}
                </tbody>
            </Table>
        </div>
    );
}

export {FlowSearchResults};

import React, {useState} from "react";
import {Icon, Label, StatusIndicator, TableCell, Tooltip} from "@nike/eds";
import {formatDate} from "../shared/DateFormatter";
import {RequestsForFlow} from "./RequestsForFlow";

function FlowSearchResultItem({searchResult, structuredLogsService}) {

    const cellStyle = {
        color : searchResult.errorCode ? 'red' : 'black'
    };

    const showPointer = {
        cursor: "pointer"
    }

    const noPadding = {
        padding: "0px 0px 8px 0px"
    }

    const [flowDetail, setFlowDetail] = useState(false);

    const showFlowDetail = () => setFlowDetail(!flowDetail)

    return (
        <>
        <tr>
            <TableCell style={{...showPointer}} onClick={showFlowDetail}>
                <Icon name={flowDetail ? "CaretDown" : "CaretRight"} size="m"/>
            </TableCell>
            <TableCell style={cellStyle}>{formatDate(searchResult.timestamp)}</TableCell>
            <TableCell style={cellStyle}>{searchResult.businessReference}</TableCell>
            <TableCell style={cellStyle}>{searchResult.type}</TableCell>
            <TableCell style={cellStyle}>{searchResult.detail}</TableCell>
            <TableCell style={cellStyle}>{searchResult.shippingPoint}</TableCell>
            <TableCell style={cellStyle}>{searchResult.inventoryLocation}</TableCell>
            <TableCell style={cellStyle}>
                <Label font="body-3">
                    {searchResult.status === null && <StatusIndicator status="warning" size="small" label=""/> }
                    {(searchResult.status === "WAITING" || searchResult.status === "PROCESSING" || searchResult.status === "NEW") && <StatusIndicator status="warning" size="small" label=""/>}
                    {(searchResult.status === "PROCESSED" || searchResult.status === "IGNORED" || searchResult.status === "PUBLISHED") && <StatusIndicator status="success" size="small" label="" />}
                    {searchResult.status === "FAILED" && <StatusIndicator status="danger" size="small" label="" />}
                </Label>
                {searchResult.status}
            </TableCell>
            <TableCell>
                {searchResult.status === "FAILED" &&
                <Tooltip bodySlot={searchResult.errorMessage === null ? "Not available" : searchResult.errorMessage}>
                    <Icon name="AlertCircle" size="m"/>
                </Tooltip>
                }
            </TableCell>
        </tr>
        {flowDetail ?
            <tr className="eds-table-row-shadow">
                <TableCell style={noPadding} colSpan={8}>
                    <RequestsForFlow structuredLogsService={structuredLogsService}  flowId={searchResult.id} />
                </TableCell>
            </tr>
            : undefined
        }
        </>
    );
}

export {FlowSearchResultItem};

import {useContext} from "react";
import useSWR from "swr";
import StructuredLogContext from "../providers/structuredLog-context";

const useFieldOptions = (fieldName) => {
    const structuredLogsService = useContext(StructuredLogContext);
    const {data, error} = useSWR(fieldName, (field) => structuredLogsService.listFieldOptions(field));

    if (error) {
        console.log("Fetching field options for field " + fieldName + ", with as result: ", data, error);
    }

    if (data && data.data && data.data.listFlowFieldSearch) {
        return data.data.listFlowFieldSearch.items;
    }

    return data;
};

export default useFieldOptions;
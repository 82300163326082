import React, {useEffect, useMemo, useState} from "react";
import {FilterableTable} from "./FilterableTable";
import {Spinner, Text} from "@nike/eds";
import {RequestDetailsStructuredLog} from "../../graphql/api";
import {formatDate} from "../shared/DateFormatter";
import {PayloadView} from "../shared/PayloadView";
import {mapDuration} from "../shared/DurationMapper";

function RequestDetailOverviewTab(props) {
    const [loading, setLoading] = useState(true);
    const structuredLogsService = props.structuredLogsService;
    const emptyArrayOfEvents: RequestDetailsStructuredLog[] = [];
    const [data, setData] = useState(emptyArrayOfEvents);

    const columns = useMemo(
        () => [
            {
                Header: "Timestamp",
                accessor: "timestamp",
                Cell: (props) => {
                    return <>{formatDate(props.value)}</>;
                },
            },
            {
                Header: "Duration",
                accessor: "duration",
                Cell: (props) => {
                    return <>{mapDuration(props.value)}</>;
                },
            },
            {
                Header: "Service",
                accessor: "componentName",
            },
            {
                Header: "Region",
                accessor: "region",
            },
            {
                Header: "Error description",
                accessor: "errorDescription",
            },
            {
                Header: "Message",
                accessor: "message",
            },
            {
                Header: "Payload",
                accessor: "payloadPath",
                Cell: (props) => {
                    return <PayloadView payloadRegion={props.row.original.payloadRegion}
                                 payloadBucket={props.row.original.payloadBucket}
                                 payloadPath={props.row.original.payloadPath}
                                 payloadVersion={props.row.original.payloadVersion}
                                 payloadSize={props.row.original.payloadSize}
                                 structuredLogsService={structuredLogsService}/>
                }
            }
        ],
        [loading]
    );

    useEffect(() => {
        if (loading) {
            structuredLogsService
                .getRequestDetails(props.traceId, props.logType)
                .then((result) => {
                    //array is ordered on timestamp newest to oldest
                    let previousTimestamp = null;
                    for (let item of result.data.getRequestDetails.items
                        .slice()
                        .reverse()) {
                        if (previousTimestamp !== null && previousTimestamp !== undefined) {
                            var utc1 = new Date(previousTimestamp);
                            var utc2 = new Date(item.timestamp);
                            item.duration = Math.floor(utc2.getTime() - utc1.getTime());
                            previousTimestamp = item.timestamp;
                        } else {
                            previousTimestamp = item.timestamp;
                            item.duration = 0;
                        }
                    }

                    setData(result.data.getRequestDetails.items);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loading]);

    const FetchingData = () => (
        <div style={{margin: "auto", width: "1%"}}>
            <div>
                <Spinner size="large"/>
            </div>
            <div>
                <Text>Loading...</Text>
            </div>
        </div>
    );

    return loading ? (
        <FetchingData/>
    ) : (
        <div>
            <FilterableTable data={data} columns={columns} loading={loading}/>
        </div>
    );
}

export {RequestDetailOverviewTab};

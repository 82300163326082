import moment from 'moment';

export function mapDuration(duration: number): string {
    const time = moment.duration(duration);
    return (time.days() > 0 ? String(time.days() + "(d)") : "")  +
        String(time.hours()).padStart(2,'0') + ':' +
        String(time.minutes()).padStart(2,'0') + ':' +
        String(time.seconds()).padStart(2,'0') + '.' +
        String(time.milliseconds()).padStart(3,'0');
}

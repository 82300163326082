import {AppsyncService} from './AppsyncService'
import gql from "graphql-tag";

import {
    getPayload,
    getPayloadUrlOnly,
    getRequest,
    getRequestDetails,
    listFlowsDynamicSearch,
    listRequestsDynamicSearch,
    listFlowFieldSearch
} from "../../graphql/queries";

export class StructuredLogsService {

    constructor(private appsyncService: AppsyncService) {
    }

    async getRequest(traceId: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getRequest), fetchPolicy: 'network-only', variables: {
                    traceId: traceId
                }
            }
        );
    }

    async getPayload(region: string, bucket: string, key: string, version: string, urlOnly: boolean): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(urlOnly ? getPayloadUrlOnly : getPayload),
                fetchPolicy: 'network-only',
                variables: {
                    region: region,
                    bucket: bucket,
                    key: key,
                    version: version
                }
            }
        );
    }

    async getRequestDetails(traceId: string, logType: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(getRequestDetails), fetchPolicy: 'network-only', variables: {
                    traceId: traceId,
                    logType: logType
                }
            }
        );
    }

    async listRequestForFlow(flowId: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listRequestsDynamicSearch), fetchPolicy: 'network-only', variables: {
                    flowId: flowId,
                }
            }
        );
    }

    async listFlows(nextToken: string, type: string, status: string, startDate: Date, endDate: Date, 
        businessReference: string, flowId: string, detail: string, shippingPoint: string, inventoryLocation: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
                query: gql(listFlowsDynamicSearch), fetchPolicy: 'network-only', variables: {
                    nextToken: nextToken,
                    type: type != null && type != '' ? type : null,
                    businessReference: businessReference != null && businessReference != '' ? businessReference : null,
                    status: status != null && status != '' ? status : null,
                    fromTimestamp: startDate != null ? startDate.getTime() : null,
                    toTimestamp: endDate != null ? endDate.getTime() : null,
                    flowId: flowId != null && flowId != '' ? flowId : null,
                    detail: detail != null && detail != '' ? detail : null,
                    shippingPoint: shippingPoint != null && shippingPoint != '' ? shippingPoint : null,
                    inventoryLocation: inventoryLocation != null && inventoryLocation != '' ? inventoryLocation : null
                }
            }
        );
    }

    async listFieldOptions(fieldName: string): Promise<any> {
        return this.appsyncService.appSyncClient.query({
            query: gql(listFlowFieldSearch), fetchPolicy: 'network-only', variables: {
                fieldName: fieldName
            }
        }
    );
    }

}
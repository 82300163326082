import {Box, Card, IconButton, Label, StatusIndicator} from "@nike/eds";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {RequestStructuredLog} from "../../graphql/api";
import {formatDate} from "../shared/DateFormatter";
import {mapInventoryLocation} from "../shared/InventoryLocationMapper";
import {mapDuration} from "../shared/DurationMapper";

function RequestDetailHeader(props) {

    const emptyRequest: RequestStructuredLog | Record<string, never> = {};
    const [data, setData] = useState(emptyRequest)

    const structuredLogsService = props.structuredLogsService


    structuredLogsService.getRequest(props.traceId).then(result => {
        let request = result.data.getRequest;
        setData(request)
    })

    return (
        <Box className="nesg-grid-items-center ">
            <div className="eds-grid eds-grid--m-cols-6 eds-elevation--4" style={{backgroundColor: "white"}}>
                <Card className="eds-grid--m-col-2 eds-spacing--ml-12 eds-spacing--mt-12 eds-spacing--p-4">
                    <Label font="title-6">
                        {data.customsInventoryType}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-3 eds-spacing--mt-12 eds-spacing--p-4">
                    <Label font="body-3">
                        {data.sourceId} / {data.traceId}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--mt-12 eds-spacing--p-4">
                    <IconButton
                        as={Link}
                        to={"/flow-overview"}
                        icon="CaretLeft"
                        style={{marginRight: "5px"}}
                        size={"small"}
                        label={""}/>
                    <IconButton
                        color="var(--eds-color-white)"
                        onClick={() => window.location.reload()}
                        icon="Repeat"
                        style={{marginRight: "5px"}}
                        size={"small"}
                        label={""}/>
                    {/*
                    <IconButton
                        color="var(--eds-color-white)"
                        as="a"
                        href={`https://app.signalfx.com/#/apm/traces/${props.traceId}`}
                        icon="Report"
                        size={"small"}
                        label={""}
                    />
                    */}
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--ml-12 eds-spacing--p-4">
                    <Label font="title-6">
                        Source
                    </Label>
                    <Label font="body-3">
                        {data.source}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--p-4">
                    <Label font="title-6">
                        Correlation ID
                    </Label>
                    <Label font="body-3">
                        {data.traceId}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--p-4">
                    <Label font="title-6">
                        Inventory Type
                    </Label>
                    <Label font="body-3">
                        {data.customsInventoryType}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--p-4">
                    <Label font="title-6">
                        Inventory Location
                    </Label>
                    <Label font="body-3">
                        {mapInventoryLocation(data.inventoryLocation)}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-2 eds-spacing--p-4">
                    <Label font="title-6">
                        Status
                    </Label>
                    <Label font="body-3">
                        {data.status === null && <StatusIndicator status="inactive" size="large" label="In progress" /> }
                        {data.status === "OK" && <StatusIndicator status="success" size="large" label="Ok" />}
                        {data.status === "PROCESSED" && <StatusIndicator status="success" size="large" label="Processed" />}
                        {data.status === "IGNORED" && <StatusIndicator status="success" size="large" label="Ignored" />}
                        {data.status === "ERROR" && <StatusIndicator status="danger" size="large" label="Error" />}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--ml-12 eds-spacing--mb-12 eds-spacing--p-4">
                    <Label font="title-6">
                        Event Timestamp
                    </Label>
                    <Label font="body-3">
                        {formatDate(data.timestamp)}
                    </Label>
                </Card>
                {/*
                <Card className="eds-grid--m-col-1 eds-spacing--mb-12 eds-spacing--p-4">
                    <Label font="title-6">
                        Responded time
                    </Label>
                    <Label font="body-3">
                        {formatDate(data.responseTimestamp)}
                    </Label>
                </Card>
                */}
                <Card className="eds-grid--m-col-1 eds-spacing--mb-12 eds-spacing--p-4">
                    <Label font="title-6">
                        Total duration
                    </Label>
                    <Label font="body-3">
                        {mapDuration(data.duration)}
                    </Label>
                </Card>
                <Card className="eds-grid--m-col-1 eds-spacing--mb-12 eds-spacing--p-4">
                    <Label font="title-6">
                        Error
                    </Label>
                    <Label font="body-3">
                        {data.errorDescription ? data.errorDescription : "/"}
                    </Label>
                </Card>
                <br/>
            </div>
        </Box>
    );
}

export {RequestDetailHeader};

import React, {useEffect, useState} from "react";
import "./RequestsForFlow.css";
import {RequestSearchResults} from "./RequestSearchResults";

function RequestsForFlow(props) {

    const [loading, setLoading] = useState(false);
    let [searchResults, setSearchResults] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            var result = await props.structuredLogsService.listRequestForFlow(props.flowId);
            console.log(result)
            setSearchResults(result.data.listRequestsDynamicSearch.items);
            setLoading(false);
        }
        fetchData();
    }, [props.structuredLogsService, props.flowId]);



    return (
        <div className="flowDetail">
        <RequestSearchResults searchResults={searchResults} loading={loading} structuredLogsService={props.structuredLogsService}/>
        </div>
    )
}

export {RequestsForFlow};
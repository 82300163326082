import React from "react";
import {IconButton, TableCell} from "@nike/eds";
import {Link} from "react-router-dom";
import {formatDate} from "../shared/DateFormatter";
import {PayloadView} from "../shared/PayloadView";
import {mapDuration} from "../shared/DurationMapper";

const statusMapper = (status) => {
    if (!status || status === "") {
        return "PROCESSING";
    }
    return status;
}

function RequestSearchResultItem({searchResult, structuredLogsService}) {

    const cellStyle = {
        color : searchResult.errorCode ? 'red' : 'black'
    };

    return (
        <tr>
            <TableCell style={cellStyle}>{formatDate(searchResult.timestamp)}</TableCell>
            <TableCell style={cellStyle}>{searchResult.sourceId}</TableCell>
            <TableCell style={cellStyle}>{searchResult.customsInventoryType}</TableCell>
            <TableCell style={cellStyle}>{statusMapper(searchResult.status)}</TableCell>
            <TableCell style={cellStyle}>{searchResult.businessReference}</TableCell>
            <TableCell style={cellStyle}>{mapDuration(searchResult.duration)}</TableCell>
            <TableCell style={cellStyle}><PayloadView payloadRegion={searchResult.payloadRegion}
                                                      payloadBucket={searchResult.payloadBucket}
                                                      payloadPath={searchResult.payloadPath}
                                                      payloadVersion={searchResult.payloadVersion}
                                                      payloadSize={searchResult.payloadSize}
                                                      structuredLogsService={structuredLogsService}/>
            </TableCell>
            <TableCell style={cellStyle}>
                <IconButton
                    variant="ghost"
                    as={Link}
                    to={"details/" + searchResult.traceId}
                    label=""
                    icon="Show"
                />
            </TableCell>
        </tr>
    );
}

export {RequestSearchResultItem};
